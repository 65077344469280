import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoIosTimer,
  IoIosSettings,
  IoIosInfinite,
  IoIosEye,
  IoIosCamera,
  IoIosPeople,
  IoIosBook,
  IoIosContact,
  IoIosPin,
  IoIosFlash,
  IoIosPause,
  IoIosPaperPlane,
} from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  PageHeader,
  CustomerCard,
  Feature,
  CallToAction,
} from "../components/site/";

const Timeclock = (props) => {
  const customerData = useStaticQuery(graphql`
    query {
      allContentfulCustomerStory(
        filter: { name: { in: ["Brazen Clothing", "Delivereasy"] } }
      ) {
        edges {
          node {
            name
            url
            heroQuote
            industry
            banner {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            logo {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Photo Time Clock | Time & Attendance Software | PayHero"
        description="Looking To Manage Staff Attendance & Payroll? PayHero Allows You Do It All In One Place Online. Includes Photo Time Clock. Create an account today."
        pathname={props.location.pathname}
      />
      <PageHeader
        feature="Photo Time Clock"
        title={[
          "Track employee time with  easy to use ",
          <span key={0} className="-fontPrimary">
            clock in and out tools
          </span>,
        ]}
        description="Increase the accuracy of your wage bill by paying staff for the hours they really work."
        imagePath="PayHeroShift_ClockInEntry.png"
      />
      <Container>
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoIosContact />
                <br />
                Start shifts with a selfie
              </h3>
              <p>
                Capture start, end and break times with photo clock in & out to
                easily create <Link to="/timesheets">online timesheets</Link>.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosPin />
                <br />
                Eliminate buddy punching
              </h3>
              <p>
                Photos record that the employee was onsite when they started and
                finished their shift.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosFlash />
                <br />
                Run pays in a jiffy
              </h3>
              <p>
                Confidently run <Link to="/payroll">payroll</Link> from time
                clock entries with no data entry or manual calculations.
              </p>
            </Box>
          </Row>
          <hr />
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                alt="Clock In & Out | Photo Time Clock | PayHero"
                filename="PayHeroShift_Home.png"
                maxWidth={550}
                centerImage
                addShadow
                rounded
              />
            </Box>
            <Box size={50} stackGap={40}>
              <h2>
                Photo Time Clock App
                <br />
                <span className="-fontLight">
                  No more timesheet stress. Record exact start, end and break
                  times for fast, accurate payroll.
                </span>
              </h2>
              <Feature
                icon={<IoIosTimer key={0} />}
                title="Automatic timesheets"
                description="Clock in and outs automatically update timesheets in your payroll. No more collecting paper timesheets, checking the hours have been added up correctly and typing them into your payroll system."
              />
              <Feature
                icon={<IoIosPause key={0} />}
                title="Take a break"
                description="When your staff take breaks, PayHero will figure out if the break should be a paid 10-minute rest break or an unpaid 30-minute meal break. PayHero calculates the point at which the break is taken within the shift, the length of the break, and the total duration of the shift, to then determine whether it’s a paid or unpaid break."
              />
              <Feature
                icon={<IoIosSettings key={0} />}
                title="Easy set up"
                description="Create an account, enter some employees, open the app and you’re ready to go. No huge set up costs or annual fees like with fingerprint scanners or card systems."
              />
              <Feature
                icon={<IoIosInfinite key={0} />}
                title="Works when you’re offline"
                description="When your internet goes down, your Time Clock app keeps working. It will store time entries and send them through to your payroll when the connection is back up."
              />
              <Feature
                icon={<IoIosEye key={0} />}
                title="Visibility of who's onsite"
                description="Get a head start on the health & safety requirements of your venue by always having a record of who’s currently working onsite at any time. Login to the admin app to see who is currently clocked in and on break."
              />
            </Box>
          </Row>
          <Row stackGap={30}>
            {customerData.allContentfulCustomerStory.edges.map((edge, i) => {
              return (
                <Box size={50} key={i}>
                  <CustomerCard
                    slug={edge.node.url}
                    banner={edge.node.banner.gatsbyImageData}
                    logo={edge.node.logo.gatsbyImageData}
                    quote={edge.node.heroQuote}
                    industry={edge.node.industry}
                    company={edge.node.name}
                  />
                </Box>
              );
            })}
          </Row>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={40}>
              <h2>
                Attendance management made easy
                <br />
                <span className="-fontLight">
                  The easiest and most accurate way of tracking your employees’
                  time.
                </span>
              </h2>
              <Feature
                icon={<IoIosCamera key={0} />}
                title="Clock in and out"
                description="Employees clock in and out of shifts with a selfie on the Shift app, or with GPS locations from their mobile phone."
              />
              <Feature
                icon={<IoIosPeople key={0} />}
                title="Live attendance"
                description="See which employees are currently clocked in and who’s on break."
              />
              <Feature
                icon={<IoIosBook key={0} />}
                title="Time history"
                description="See historical time entries and associated photos or locations."
              />
              <Feature
                icon={<IoIosPaperPlane key={0} />}
                title="Send to payroll"
                description={[
                  <span key={0}>
                    Employee timesheets are automatically loaded into PayHero
                    for fast and accurate{" "}
                  </span>,
                  <Link key={1} to="/payroll">
                    payroll processing
                  </Link>,
                  <span key={2}>.</span>,
                ]}
              />
            </Box>
            <Box size={50}>
              <Image
                alt="Photo Time Clock | PayHero"
                filename="PayHeroShift_ClockInEntry.png"
                addShadow
                centerImage
                rounded
                maxWidth={500}
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default Timeclock;
